<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">상담사별 스킬관리</h1>
    <!-- tabs -->
    <v-tabs class="tabs-form mt-2" slider-color="#6C6E7A" slider-size="3">
      <v-tab>스킬별 상담사설정</v-tab>
      <v-tab>상담사별 스킬설정</v-tab>
      <!-- tab : 스킬사별 상담사설정 -->
      <v-tab-item>
        <div class="box-wrap mt-0">
          <div class="box-ct d-flex">
            <div>
              <div class="">
                <div class="form-inp sm">
                  <v-select
                      :items="custItems"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="회사구분"
                      placeholder="선택하세요"
                      v-model="ASP_NEWCUST_KEY"
                      v-on:change="companyChange"
                      :readonly="RDOnly"
                  >
                    <template v-slot:label>
                      회사구분
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                      :items="spstInqryTypItems"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                      v-model="SPST_INQRY_TYP_CD"
                      @change="getSpstInqryTypLevel2"
                  >
                    <template v-slot:label>
                      스킬(문의유형 1)
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                      :items="spstInqryTypItems2"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                      v-model="SPST_INQRY_TYP_CD2"
                  >
                    <template v-slot:label>
                      문의유형 2
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>
                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    label="사용자ID"
                    id="id"
                    outlined
                    hide-details
                    v-model="USER_ID"
                    @keyup.enter=searchUser
                >
                </v-text-field>
                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    label="사용자명"
                    id="id"
                    outlined
                    hide-details
                    v-model="USER_NM"
                    @keyup.enter=searchUser
                >
                </v-text-field>
              </div>
              <div class="mt-2">
                <div class="form-inp sm">
                  <v-select
                      :items="dropA"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="소속A"
                      placeholder="선택하세요"
                      v-model="USER_ATTR_A"
                  ></v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                      :items="dropB"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="소속B"
                      placeholder="선택하세요"
                      v-model="USER_ATTR_B"
                  ></v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                      :items="dropC"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="소속C"
                      placeholder="선택하세요"
                      v-model="USER_ATTR_C"
                  ></v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                      :items="dropD"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="소속D"
                      placeholder="선택하세요"
                      v-model="USER_ATTR_D"
                  ></v-select>
                </div>

                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    label="사용자닉네임"
                    id="id"
                    outlined
                    hide-details
                    v-model="USER_NICK"
                    @keyup.enter=searchUser
                >
                </v-text-field>
              </div>
            </div>
            <div class="ml-auto align-self-center">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch1')" outlined class="btn-etc2" @click="searchUser">조회</v-btn>
            </div>
          </div>
        </div>
        <!-- 미할당스킬 -->
        <div class="box-wrap">
          <h2 class="tit-h2 d-flex">
            미할당스킬
            <div class="ml-auto">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave1')" outlined class="btn-point" @click="saveBtn('tab1')">저장</v-btn>
            </div>
          </h2>
          <div class="box-ct d-flex">
            <div style="width: 47%">
              <v-data-table
                  dense
                  height="385px"
                  :headers="gridDataHeaders"
                  :items="gridDataText"
                  :items-per-page="30"
                  item-key="USER_ID"
                  :page.sync="page"
                  hide-default-footer
                  show-select
                  class="grid-default"
                  fixed-header
                  @page-count="pageCount = $event"
                  v-model="selectedDivGridNoAllocList"
                  no-data-text="검색된 결과가 없습니다."
                  v-on:click:row="getListSel"
                  :item-class="isActiveRowLeft"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="totalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
              <p class="caption text-right">
                총<strong class="clr-error">{{ TOT_COUNT }}</strong
              >건
              </p>
            </div>
            <div class="btn-arrow-wrap-vertical">
              <div class="">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow1')" class="btn-arrow" plain small @click="moveLeftBtn('tab1')">
                  <v-icon small class="svg-arrow-left"></v-icon>
                </v-btn>
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow1')" class="btn-arrow" plain small @click="moveRight('tab1')">
                  <v-icon small class="svg-arrow-right"></v-icon>
                </v-btn>
              </div>
            </div>
            <div class="flex-grow-1">
              <v-data-table
                  dense
                  height="385px"
                  :headers="gridDataHeaders"
                  :items="gridDataText2"
                  :items-per-page="30"
                  item-key="USER_ID"
                  :page.sync="page2"
                  hide-default-footer
                  show-select
                  class="grid-default "
                  fixed-header
                  @page-count="pageCount2 = $event"
                  v-model="selectedDivGridAllocList"
                  no-data-text="검색된 결과가 없습니다."
                  v-on:click:row="getListSel2"
                  :item-class="isActiveRowRight"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                    v-model="page2"
                    :length="pageCount2"
                    :total-visible="totalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>
          <p class="caption text-right">
            <strong class="clr-error">{{ TOT_COUNT2 }}</strong
            >건의 할당 사용자가 검색되었습니다.
          </p>
        </div>
        <!--// 미할당스킬 -->
      </v-tab-item>
      <!-- tab : 상담사별 스킬설정 -->
      <v-tab-item>
        <div class="box-wrap mt-0">
          <div class="box-ct d-flex">
            <div>
              <div class="">
                <div class="form-inp sm">
                  <v-select
                      :items="custItems"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="회사구분"
                      placeholder="선택하세요"
                      v-model="ASP_NEWCUST_KEY_2"
                  >
                    <template v-slot:label>
                      회사구분
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-select>
                </div>

                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    id="id"
                    label="사용자 ID"
                    outlined
                    hide-details
                    disabled
                    v-model="USER_ID_2"
                >
                  <template v-slot:label>
                    사용자 ID
                    <v-icon color="#EF9191">mdi-circle-small</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    label="이름"
                    id="id"
                    outlined
                    hide-details
                    disabled
                    v-model="USER_NM_2"
                >
                </v-text-field>
                <v-text-field
                    class="form-inp ml-2"
                    name="name"
                    label="닉네임"
                    id="id"
                    outlined
                    hide-details
                    disabled
                    v-model="USER_NICK_2"
                >
                </v-text-field>
                <!-- 조회 버튼 -->
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')" dark small class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain @click="[btnUser(), showDialog('MP02')]">
                  <v-icon small class="svg-main-searh white"></v-icon>
                </v-btn>
                <!-- 새로고침 버튼 -->
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')" dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
                  plain @click="initCus"
                  >
                  <v-icon small class="svg-main-refresh"></v-icon>
                </v-btn>
              </div>
            </div>
            <div class="ml-auto align-self-center">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch2')" outlined class="btn-etc2" @click="searchSkillBtn">조회</v-btn>
            </div>
          </div>
        </div>
        <!-- 미할당스킬 -->
        <div class="box-wrap">
          <h2 class="tit-h2 d-flex">
            미할당스킬
            <div class="ml-auto">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow2')" class="btn-arrow" plain small @click="modifyBtn('up')">
                <v-icon small class="svg-arrow-top"></v-icon>
              </v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow2')" class="btn-arrow ml-2" plain small @click="modifyBtn('down')">
                <v-icon small class="svg-arrow-down"></v-icon>
              </v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave2')" outlined class="btn-point ml-2" @click="saveBtn('tab2')">저장</v-btn>
            </div>
          </h2>
          <div class="box-ct d-flex">
            <div style="width: 47%">
              <v-data-table
                  dense
                  height="468px"
                  :headers="gridDataHeaders2"
                  :items="gridDataText3"
                  :items-per-page="30"
                  item-key="TALK_INQRY_CD"
                  :page.sync="page3"
                  hide-default-footer
                  show-select
                  class="grid-default "
                  fixed-header
                  @page-count="pageCount3 = $event"
                  v-model="seletedAuthGroupNoAlloc"
                  no-data-text="검색된 결과가 없습니다."
                  v-on:click:row="getListSel"
                  :item-class="isActiveRowLeft2"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                    v-model="page3"
                    :length="pageCount3"
                    :total-visible="totalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
              <p class="caption text-right">
                <strong class="clr-error">{{ TOT_COUNT3 }}</strong
                >건의 미할당 스킬이 검색되었습니다.
              </p>
            </div>
            <div class="btn-arrow-wrap-vertical">
              <div class="">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow3')" class="btn-arrow" plain small @click="moveLeftBtn('tab2')">
                  <v-icon small class="svg-arrow-left"></v-icon>
                </v-btn>
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow3')" class="btn-arrow" plain small @click="moveRight('tab2')">
                  <v-icon small class="svg-arrow-right"></v-icon>
                </v-btn>
              </div>
            </div>

            <div class="flex-grow-1">
              <v-data-table
                  dense
                  height="468px"
                  :headers="gridDataHeaders3"
                  :items="gridDataText4"
                  :items-per-page="30"
                  item-key="TALK_INQRY_CD"
                  :page.sync="page4"
                  hide-default-footer
                  show-select
                  class="grid-default"
                  fixed-header
                  @page-count="pageCount4 = $event"
                  v-model="seletedAuthGroupAlloc"
                  no-data-text="검색된 결과가 없습니다."
                  v-on:click:row="getListSel2"
                  :item-class="isActiveRowRight2"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                    v-model="page4"
                    :length="pageCount4"
                    :total-visible="totalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
              <p class="caption text-right">
                <strong class="clr-error">{{ TOT_COUNT4 }}</strong
                >건의 할당 스킬이 검색되었습니다.
              </p>
            </div>
          </div>
        </div>
        <!--// 미할당스킬 -->
      </v-tab-item>
    </v-tabs>
    <!-- 고객정보 모달창 -->
    <div class="">
      <v-dialog
          max-width="1400"
          max-height="1200"
          v-model="DialogMP02"
          content-class="square-modal"
      >
        <dialog-MP02
            headerTitle="고객정보"
            @hide="hideDialog('MP02')"
            :userInfo="userInfo"
            @input="userInfo = $event"
            @selectUser="[hideDialog('MP02'), setUser()]"
        >
        </dialog-MP02>
        <template v-slot:body>

        </template>
      </v-dialog>
    </div>
    <!-- 고객정보 모달창 -->
  </div>
</template>

<script>
import {mixin} from "../../mixin/mixin";
import DialogMP02 from "./MP02.vue";

export default {
  name      : "MENU_M810603", //name은 'MENU_' + 파일명 조합
  mixins    : [mixin],
  components: {
    DialogMP02
  },
  data() {
    return {
      DialogMP02        : false,
      userInfo          : {},
      menu1             : false,
      menu2             : false,
      radioGroup        : [
        {
          text: "사용자명",
        },
        {
          text: "최근접속일시",
        },
      ],
      radioGroupSelected: "사용자명",

      //resString : '',
      custItems         : [],
      listItems         : [
        {
          ATTR_CD    : "A",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "B",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "C",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "D",
          ATTR_DIV_CD: "",
        }
      ],
      dropA             : [{CD_NM: "전체", CD: ""}],
      dropB             : [{CD_NM: "전체", CD: ""}],
      dropC             : [{CD_NM: "전체", CD: ""}],
      dropD             : [{CD_NM: "전체", CD: ""}],


      spstInqryTypItems : [{CD_NM: "선택", CD: "*"}],
      spstInqryTypItems2: [{CD_NM: "전체", CD: "*"}],

      gridDataHeaders : [
        {
          text    : "사용자ID",
          value   : "USER_ID",
          align   : "center",
          sortable: true,
        },
        {
          text    : "사용자명",
          value   : "USER_NM",
          align   : "center",
          sortable: true,
        },
        {
          text    : "사용자닉네임",
          value   : "USER_NICK",
          align   : "center",
          sortable: true,
        },
        {text: "소속A", value: "ATTR_DIV_NM_A", align: "center", sortable: true,},
        {text: "소속B", value: "ATTR_DIV_NM_B", align: "center", sortable: true,},
        {text: "소속C", value: "ATTR_DIV_NM_C", align: "center", sortable: true,},
        {text: "소속D", value: "ATTR_DIV_NM_D", align: "center", sortable: true,},
      ],
      gridDataHeaders2: [
        {
          text    : "문의유형명",
          value   : "INQRY_TYP_NM",
          align   : "left",
          sortable: true,
        },
        {
          text    : "문의유형 타입",
          value   : "INQRY_TYPE",
          align   : "center",
          sortable: true,
        },
        {
          text    : "정렬순서",
          value   : "SORT_ORD",
          align   : "center",
          width   : "90px",
          sortable: true,
        },
      ],
      gridDataHeaders3: [
        {
          text    : "문의유형명",
          value   : "INQRY_TYP_NM",
          align   : "left",
          sortable: true,
        },
        {
          text    : "문의유형 타입",
          value   : "INQRY_TYPE",
          align   : "center",
          sortable: true,
        },
        {
          text    : "우선순위",
          value   : "SKILL_RANK",
          align   : "center",
          width   : "90px",
          sortable: true,
        },
      ],
      gridDataText    : [],
      gridDataText2   : [],
      gridDataText3   : [],
      gridDataText4   : [],

      asgnGridData : [],
      asgnGridData2: [],

      //paging
      page         : 1,
      page2        : 1,
      page3        : 1,
      page4        : 1,
      itemsPerPage : 15,
      pageCount    : 0,
      pageCount2   : 0,
      pageCount3   : 0,
      pageCount4   : 0,
      totalVisible : 10,
      TOT_COUNT    : 0,
      TOT_COUNT2   : 0,
      TOT_COUNT3   : 0,
      TOT_COUNT4   : 0,
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",

      //v-model
      ASP_NEWCUST_KEY   : '',
      ASP_NEWCUST_KEY_2 : '',
      SPST_INQRY_TYP_CD : '*',
      SPST_INQRY_TYP_CD2: '',
      USER_ID           : '',
      USER_NM           : '',
      USER_NICK         : '',
      USER_ID_2         : '',
      USER_NM_2         : '',
      USER_NICK_2       : '',
      USER_ATTR_A       : '',
      USER_ATTR_B       : '',
      USER_ATTR_C       : '',
      USER_ATTR_D       : '',


      //그리드 v-model
      selectedDivGridNoAllocList: [],
      selectedDivGridAllocList  : [],
      seletedAuthGroupNoAlloc   : [],
      seletedAuthGroupAlloc     : [],
      detailData                : [],
      detailData2                : [],

      //msg
      msgList:
          {
            noAspCustKey : "회사구분란에서 회사를 선택후 이용해주십시오",
            noAlloSelected : "미할당 사용자가 선택되지 않았습니다.",
            alloSelected   : "할당 사용자가 선택되지 않았습니다.",
            requiredInqry  : "스킬(문의유형1)은(는) 필수입력입니다.",
            requiredUser : "사용자 ID 은(는) 필수입력입니다.",
            skillNotSeleted: "선택된 스킬이 없습니다.",
            cantUp         : "선택된 스킬의 우선순위를 올릴 수 없습니다.",
            chkUpDown      : {"up": "선택한 스킬의 우선순위를 올리시겠습니까?", "down": "선택한 스킬의 우선순위를 내리시겠습니까?"},
            noGridList     : "데이터를 먼저 조회해주세요",
            saveMsg        : "저장하시겠습니까?",
            savedMsg       : "정상적으로 등록되었습니다.",
            moveLeft       : {"tab1": "선택한 사용자를 미할당 처리 하시겠습니까?", "tab2": "선택한 스킬을 미할당 처리 하시겠습니까?"}
          },
          
      RDOnly:false,

    };
  },
  methods: {

    getListSel(item){
      // 선택한 아이템 저장
      this.detailData = JSON.parse(JSON.stringify(item));
    },

    getListSel2(item){
      // 선택한 아이템 저장
      this.detailData2 = JSON.parse(JSON.stringify(item));
    },

    isActiveRowLeft(item){
      return item.USER_ID== this.detailData.USER_ID? 'active':'';
    },

    isActiveRowRight(item){
      return item.USER_ID== this.detailData2.USER_ID? 'active':'';
    },


    isActiveRowLeft2(item){
      return item.TALK_INQRY_CD== this.detailData.TALK_INQRY_CD? 'active':'';
    },

    isActiveRowRight2(item){
      return item.TALK_INQRY_CD== this.detailData2.TALK_INQRY_CD? 'active':'';
    },

    showDialog(menu) {
      this[`Dialog${menu}`] = true;
    },

    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },

    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
    },

    //회사선택시 조회
    companyChange() {
      this.getAttrList();
      this.getSpstInqryTypList();

    },

    //스킬 가져오기
    async getSpstInqryTypList() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["SERVICE"] = 'chat.setting.inqry-ty-manage';
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["SPST_INQRY_TYP_CD"] ='*';
      requestData.sendData["USE_YN"] = "Y"

      let spstInqryTypResponse = await this.common_postCall(requestData, false);
      this.spstInqryTypCallback(spstInqryTypResponse);
    },

    spstInqryTypCallback(response) {
      let SPST_INQRY_TYP_CD = response.DATA[0].CD
      this.getSpstInqryTypLevel1(SPST_INQRY_TYP_CD);
    },

    //문의유형 가져오기, 추후 공통코드 활용할 것
    async getSpstInqryTypLevel1(SPST_INQRY_TYP_CD) {
      this.spstInqryTypItems = [{CD_NM: "선택", CD: "*"}];

      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["SERVICE"] = 'chat.setting.inqry-ty-manage';
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["SPST_INQRY_TYP_CD"] = SPST_INQRY_TYP_CD;
      requestData.sendData["USE_YN"] = "Y"

      let response = await this.common_postCall(requestData, false);
      this. spstInqryTypLevel1Callback(response);
    },

    spstInqryTypLevel1Callback(response) {
      this.spstInqryTypItems.push(...response.DATA);
    },

    async getSpstInqryTypLevel2() {
      this.spstInqryTypItems2 = [{CD_NM: "전체", CD: "*"}];

      if(this.SPST_INQRY_TYP_CD == '*') {
        return false;
      }

      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["SERVICE"] = 'chat.setting.inqry-ty-manage';
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["SPST_INQRY_TYP_CD"] = this.SPST_INQRY_TYP_CD;
      requestData.sendData["USE_YN"] = "Y"

      let response = await this.common_postCall(requestData, false);
      this.getSpstInqryTypLevel2Callback(response);
    },

    getSpstInqryTypLevel2Callback(response) {
      this.spstInqryTypItems2.push(...response.DATA);
    },

    //attr가져오기
    async getAttrList() {
      let aspNewcustKey = this.ASP_NEWCUST_KEY;
      //회사구분 변경시 소속 콤보박스에 selected 된것 초기화

      this.dropA = await this.mixin_attr_get(aspNewcustKey, "A","전체");
      this.dropB = await this.mixin_attr_get(aspNewcustKey, "B","전체");
      this.dropC = await this.mixin_attr_get(aspNewcustKey, "C","전체");
      this.dropD = await this.mixin_attr_get(aspNewcustKey, "D","전체");

      this.USER_ATTR_A = "";
      this.USER_ATTR_B = "";
      this.USER_ATTR_C = "";
      this.USER_ATTR_D = "";
      },

    //유저 조회
    searchUser() {
      if(this.ASP_NEWCUST_KEY == null || this.ASP_NEWCUST_KEY == ''){
        this.common_alert(this.msgList.noAspCustKey, "error");
        return
      }

      if (this.SPST_INQRY_TYP_CD == '*') {
        this.common_alert(this.msgList.requiredInqry, "error");
        return false
      }
      //선택된 로우 초기화
      this.detailData = [];
      this.detailData2 = [];

      //그리드 초기화
      this.gridDataText = [];
      this.gridDataText2 = [];

      this.asgnUser();
      this.unasgnUser();

    },

    async unasgnUser() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/inqry-ty-unasgn-user/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.inqry-ty-unasgn-user';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["GRID_ID"] = "divGridAuthUserNoAlloc";
      requestData.headers["ROW_CNT"] = 50;
      requestData.headers["PAGES_CNT"] = 1;

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["TALK_INQRY_CD"] = this.SPST_INQRY_TYP_CD;
      requestData.sendData["TALK_INQRY_CD2"] = this.SPST_INQRY_TYP_CD2;
      requestData.sendData["USER_ID"] = this.USER_ID;
      requestData.sendData["USER_NM"] = this.USER_NM;
      requestData.sendData["USER_ATTR_A"] = this.USER_ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.USER_ATTR_B;
      requestData.sendData["USER_ATTR_C"] = this.USER_ATTR_C;
      requestData.sendData["USER_ATTR_D"] = this.USER_ATTR_D;
      requestData.sendData["USER_NICK"] = this.USER_NICK;


      let unasgnUserResponse = await this.common_postCall(requestData);
      this.unasgnUserCallback(unasgnUserResponse);
    },
    unasgnUserCallback(response) {
      this.gridDataText = response.DATA;
      this.TOT_COUNT = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY + this.gridDataText[i]["TALK_INQRY_CD"] + this.gridDataText[i]["USER_ID"];
        this.gridDataText[i]["id"] = id;
        this.gridDataText[i]["index"] = idx++;
      }

      //초기화
      this.selectedDivGridNoAllocList = [];

    },

    async asgnUser() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/inqry-ty-asgn-user/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.inqry-ty-asgn-user';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["GRID_ID"] = "divGridAuthUserAlloc";
      // requestData.headers["ROW_CNT"] = 50;
      // requestData.headers["PAGES_CNT"] = 1;

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["TALK_INQRY_CD"] = this.SPST_INQRY_TYP_CD;
      requestData.sendData["TALK_INQRY_CD2"] = this.SPST_INQRY_TYP_CD2;
      requestData.sendData["USER_ID"] = this.USER_ID;
      requestData.sendData["USER_NM"] = this.USER_NM;
      requestData.sendData["USER_ATTR_A"] = this.USER_ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.USER_ATTR_B;
      requestData.sendData["USER_ATTR_C"] = this.USER_ATTR_C;
      requestData.sendData["USER_ATTR_D"] = this.USER_ATTR_D;
      requestData.sendData["USER_NICK"] = this.USER_NICK;


      let asgnUserResponse = await this.common_postCall(requestData);
      this.asgnUserCallback(asgnUserResponse);
    },
    asgnUserCallback(response) {
      this.gridDataText2 = response.DATA;

      this.TOT_COUNT2 = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText2;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY + this.gridDataText2[i]["TALK_INQRY_CD"] + this.gridDataText2[i]["USER_ID"];
        this.gridDataText2[i]["id"] = id;
        this.gridDataText2[i]["index"] = idx++;
      }

      //원본저장
      this.asgnGridData = [];
      for (let i in this.gridDataText2) {
        this.asgnGridData.push(this.gridDataText2[i]);
      }

      //초기화
      this.selectedDivGridAllocList = [];

    },

    //저장컨펌창
    saveBtn(tab) {
      if (tab == 'tab1') {
        if (Object.keys(this.gridDataText).length == 0 && Object.keys(this.gridDataText2).length == 0) {
          this.common_alert(this.msgList.noGridList, "error");
          return
        }
        this.common_confirm(this.msgList.saveMsg, this.saveAsgnUser, tab, null, null, "chk");
      } else if (tab == 'tab2') {
        if (Object.keys(this.gridDataText3).length == 0 && Object.keys(this.gridDataText4).length == 0) {
          this.common_alert(this.msgList.noGridList, "error");
          return
        }
        this.common_confirm(this.msgList.saveMsg, this.saveAsgnUser, tab, null, null, "chk");
      }
    },

    //저장
    async saveAsgnUser(tab) {
      let rstSelectedGrid = [];
      let rstSelectedGrid2 = [];
      let rstSelectedGrid3 = [];
      let rstSelectedGrid4 = [];

      if (tab == 'tab1') {
        //할당에 있던 유저 중 미할당으로 된 유저들
        //this.asgnGridData = 원본
        //this.gridDataText2 = 변경
        for (let i = 0; i < this.asgnGridData.length; i++) {
          if (!JSON.stringify(this.gridDataText2).includes(JSON.stringify(this.asgnGridData[i]))) {
            rstSelectedGrid2.push(this.asgnGridData[i]);
          }
        }
        //미할당에 있던 유저 중 할등으로 된 유저들
        for (let i = 0; i < this.gridDataText2.length; i++) {
          if (!JSON.stringify(this.asgnGridData).includes(JSON.stringify(this.gridDataText2[i]))) {
            rstSelectedGrid.push(this.gridDataText2[i]);
          }
        }

        for (let i = 0; i < 2; i++) {//미할당, 할당 저장 후 재조회
          let submitList = [];

          let requestData = {
            headers : {},
            sendData: {}
          };

          // header 세팅
          requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/inqry-ty/process";
          requestData.headers["METHOD"] = "process";
          requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.inqry-ty';
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["ASYNC"] = false;

          if (rstSelectedGrid.length > 0) {
            let idx = 0;
            for (let i in rstSelectedGrid) {
              let obj = {
                divGridAuthUserAlloc_CHECKBOX: 0,
                DATA_FLAG                    : "I",
                REGR_DEPT_CD                 : "x",
                PROC_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                REGR_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                TALK_INQRY_CD                : this.SPST_INQRY_TYP_CD,
                TALK_INQRY_CD2               : this.SPST_INQRY_TYP_CD2,
                USER_ID                      : rstSelectedGrid[i].USER_ID,
                USER_NM                      : rstSelectedGrid[i].USER_NM,
                USER_NICK                    : rstSelectedGrid[i].USER_NICK,
                ASP_CUST_KEY                 : this.ASP_NEWCUST_KEY,
              };

              if (idx === 0) {
                rstSelectedGrid[i].ATTR_DIV_NM_A == '' ? obj.ATTR_DIV_NM_A = "&#91;!NULL!&#93;" : rstSelectedGrid[i].ATTR_DIV_NM_A;
                rstSelectedGrid[i].ATTR_DIV_NM_B == '' ? obj.ATTR_DIV_NM_B = "&#91;!NULL!&#93;" : rstSelectedGrid[i].ATTR_DIV_NM_B;
                rstSelectedGrid[i].ATTR_DIV_NM_C == '' ? obj.ATTR_DIV_NM_C = "&#91;!NULL!&#93;" : rstSelectedGrid[i].ATTR_DIV_NM_C;
                rstSelectedGrid[i].ATTR_DIV_NM_D == '' ? obj.ATTR_DIV_NM_D = "&#91;!NULL!&#93;" : rstSelectedGrid[i].ATTR_DIV_NM_D;
              } else {
                obj.ATTR_DIV_NM_A = rstSelectedGrid[i].ATTR_DIV_NM_A;
                obj.ATTR_DIV_NM_B = rstSelectedGrid[i].ATTR_DIV_NM_B;
                obj.ATTR_DIV_NM_C = rstSelectedGrid[i].ATTR_DIV_NM_C;
                obj.ATTR_DIV_NM_D = rstSelectedGrid[i].ATTR_DIV_NM_D;
              }
              submitList.push(obj);
              idx++;
            }
            // sendData 세팅
            requestData.sendData["PROC_TYPE"] = 'INQRY';
            requestData.sendData["seletedList"] = submitList;

            rstSelectedGrid = [];


          } else if (rstSelectedGrid2.length > 0) {

            let idx = 0;
            for (let i in rstSelectedGrid2) {
              let obj = {
                divGridAuthUserAlloc_CHECKBOX: 1,
                DATA_FLAG                    : "D",
                REGR_DEPT_CD                 : "x",
                PROC_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                REGR_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                TALK_INQRY_CD                : this.SPST_INQRY_TYP_CD,
                TALK_INQRY_CD2               : this.SPST_INQRY_TYP_CD2,
                USER_ID                      : rstSelectedGrid2[i].USER_ID,
                USER_NM                      : rstSelectedGrid2[i].USER_NM,
                USER_NICK                    : rstSelectedGrid2[i].USER_NICK,
                ASP_CUST_KEY                 : this.ASP_NEWCUST_KEY,
              };

              if (idx === 0) {
                rstSelectedGrid2[i].ATTR_DIV_NM_A == '' ? obj.ATTR_DIV_NM_A = "&#91;!NULL!&#93;" : rstSelectedGrid2[i].ATTR_DIV_NM_A;
                rstSelectedGrid2[i].ATTR_DIV_NM_B == '' ? obj.ATTR_DIV_NM_B = "&#91;!NULL!&#93;" : rstSelectedGrid2[i].ATTR_DIV_NM_B;
                rstSelectedGrid2[i].ATTR_DIV_NM_C == '' ? obj.ATTR_DIV_NM_C = "&#91;!NULL!&#93;" : rstSelectedGrid2[i].ATTR_DIV_NM_C;
                rstSelectedGrid2[i].ATTR_DIV_NM_D == '' ? obj.ATTR_DIV_NM_D = "&#91;!NULL!&#93;" : rstSelectedGrid2[i].ATTR_DIV_NM_D;
              } else {
                obj.ATTR_DIV_NM_A = rstSelectedGrid2[i].ATTR_DIV_NM_A;
                obj.ATTR_DIV_NM_B = rstSelectedGrid2[i].ATTR_DIV_NM_B;
                obj.ATTR_DIV_NM_C = rstSelectedGrid2[i].ATTR_DIV_NM_C;
                obj.ATTR_DIV_NM_D = rstSelectedGrid2[i].ATTR_DIV_NM_D;
              }

              //전체 미할당처리
              if (Object.keys(rstSelectedGrid2).length < 1) {
                obj.DATA_FLAG = "DEL_ALL";
              }
              submitList.push(obj);
              idx++;
            }
            // sendData 세팅
            requestData.sendData["PROC_TYPE"] = 'INQRY';
            requestData.sendData["seletedList"] = submitList;
            rstSelectedGrid2 = [];
          }
          let response = await this.common_postCall(requestData);

          //재조회
          if (i == 1) {
            this.saveAsgnUserCallback(response, tab);
          }
        }
      } else if (tab == 'tab2') {
        //할당에 있던 유저 중 미할당으로 된 유저들
        //this.asgnGridData = 원본
        //this.gridDataText2 = 변경
        for (let i = 0; i < this.asgnGridData2.length; i++) {
          if (!JSON.stringify(this.gridDataText4).includes(JSON.stringify(this.asgnGridData2[i]))) {
            rstSelectedGrid4.push(this.asgnGridData2[i]);
          }
        }
        //미할당에 있던 유저 중 할등으로 된 유저들
        for (let i = 0; i < this.gridDataText4.length; i++) {
          if (!JSON.stringify(this.asgnGridData2).includes(JSON.stringify(this.gridDataText4[i]))) {
            rstSelectedGrid3.push(this.gridDataText4[i]);
          }
        }
      }

      for (let i = 0; i < 2; i++) {//미할당, 할당 저장 후 재조회
        let submitList = [];

        let requestData = {
          headers : {},
          sendData: {}
        };

        // header 세팅
        requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/inqry-ty/process";
        requestData.headers["METHOD"] = "process";
        requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.inqry-ty';
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        if (rstSelectedGrid3.length > 0) {
          //||SKILL_RANK||TALK_INQRY_CD||USER_ID||DATA_FLAG||PROC_ID||REGR_DEPT_CD||REGR_ID||PROC_TYPE
          for (let i in rstSelectedGrid3) {
            let obj = {
              divGridAuthGroupAlloc_CHECKBOX: 0,
              INQRY_TYP_NM                  : rstSelectedGrid3[i].INQRY_TYP_NM,
              INQRY_TYPE                    : rstSelectedGrid3[i].INQRY_TYPE,
              INQRY_USE_CHANNEL             : rstSelectedGrid3[i].INQRY_USE_CHANNEL,
              SKILL_RANK                    : rstSelectedGrid3[i].SKILL_RANK,
              TALK_INQRY_CD                 : rstSelectedGrid3[i].TALK_INQRY_CD,
              USER_ID                       : rstSelectedGrid3[i].USER_ID,
              DATA_FLAG                     : "I",
              PROC_ID                       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              REGR_DEPT_CD                  : "x",
              REGR_ID                       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              ASP_CUST_KEY                  : this.ASP_NEWCUST_KEY_2,
            };

            submitList.push(obj);
          }
          // sendData 세팅
          requestData.sendData["PROC_TYPE"] = 'USER';
          requestData.sendData["seletedList"] = submitList;
          rstSelectedGrid3 = [];

        } else if (rstSelectedGrid4.length > 0) {
          let idx = 0;
          for (let i in rstSelectedGrid4) {
            let obj = {
              divGridAuthGroupAlloc_CHECKBOX: 1,
              INQRY_TYP_NM                  : rstSelectedGrid4[i].INQRY_TYP_NM,
              INQRY_TYPE                    : rstSelectedGrid4[i].INQRY_TYPE,
              INQRY_USE_CHANNEL             : rstSelectedGrid4[i].INQRY_USE_CHANNEL,
              SKILL_RANK                    : rstSelectedGrid4[i].SKILL_RANK,
              TALK_INQRY_CD                 : rstSelectedGrid4[i].TALK_INQRY_CD,
              USER_ID                       : rstSelectedGrid4[i].USER_ID,
              DATA_FLAG                     : "D",
              PROC_ID                       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              REGR_DEPT_CD                  : "x",
              REGR_ID                       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              ASP_CUST_KEY                  : this.ASP_NEWCUST_KEY_2,
            };

            //전체 미할당처리
            if (Object.keys(rstSelectedGrid4).length < 1) {
              obj.DATA_FLAG = "DEL_ALL";
            }
            submitList.push(obj);
          }
          // sendData 세팅
          requestData.sendData["PROC_TYPE"] = 'USER';
          requestData.sendData["seletedList"] = submitList;
          rstSelectedGrid4 = [];
        }
        let response = await this.common_postCall(requestData);

        //재조회
        if (i == 1) {
          this.saveAsgnUserCallback(response, tab);
        }
      }
    },
    saveAsgnUserCallback(response, tab) {
      console.log(response);
      this.common_alert(this.msgList.savedMsg, "done");

      if (tab == 'tab1') {
        this.searchUser();
      } else if (tab == 'tab2') {
        this.searchSkillBtn();
      }

    },

    //-----------------------------------------------------------------------------------------------------

    /*
    * 상담사별 스킬설정
    */

    //유저검색팝업
    btnUser() {
      let user = {ASP_CUST_KEY: this.ASP_NEWCUST_KEY_2};
      this.userInfo = user;
    },

    setUser() {
      this.USER_ID_2 = this.userInfo.USER_ID;
      this.USER_NM_2 = this.userInfo.USER_NM;
      this.USER_NICK_2 = this.userInfo.USER_NICK;
    },


    //초기화 버튼
    initCus() {
      this.USER_ID_2 = "";
      this.USER_NM_2 = "";
      this.USER_NICK_2 = "";
    },

    //상담사별 스킬설정 조회
    searchSkillBtn() {
      if(this.ASP_NEWCUST_KEY_2 == null || this.ASP_NEWCUST_KEY_2 == ''){
        this.common_alert(this.msgList.noAspCustKey, "error");
        return
      }
      if(this.mixin_isEmpty(this.USER_ID_2)){
        this.common_alert(this.msgList.requiredUser, "error");
        return
      }

      //선택된 로우 초기화
      this.detailData = [];
      this.detailData2 = [];

      this.searchUnasgnSkill(); //미할당 스킬 조회
      this.searchAsgnSkill(); //할당 스킬 조회
    },

    //미할당 스킬 조회
    async searchUnasgnSkill() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/unasgn-inqry-ty/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.unasgn-inqry-ty';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["GRID_ID"] = "divGridAuthGroupNoAlloc";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY_2"] = this.ASP_NEWCUST_KEY_2;
      requestData.sendData["USER_ID"] = this.USER_ID_2;
      requestData.sendData["USER_NM"] = this.USER_NM_2;
      requestData.sendData["USER_NICK"] = this.USER_NICK_2;
      requestData.sendData["NULLABLE"] = "IS NULL";
      requestData.sendData["USER_ATTR_A"] = "";			//상담원명
      requestData.sendData["USER_ATTR_B"] = "";			//상담원명
      requestData.sendData["USER_ATTR_C"] = "";			//상담원명
      requestData.sendData["USER_ATTR_D"] = "";			//상담원명
      requestData.sendData["ROW_CNT"] = 100;				//페이징_ROW_CNT
      requestData.sendData["PAGES_CNT"] = 1;				//페이징_PAGES_CNT

      let response = await this.common_postCall(requestData);
      this.searchUnasgnSkillCallback(response);
    },
    searchUnasgnSkillCallback(response) {
      this.gridDataText3 = response.DATA;
      this.TOT_COUNT3 = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText3;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY_2 + this.gridDataText3[i]["TALK_INQRY_CD"] + this.gridDataText3[i]["USER_ID"];
        this.gridDataText3[i]["id"] = id;
        this.gridDataText3[i]["index"] = idx++;
      }

      //초기화
      this.seletedAuthGroupNoAlloc = [];


    },
    //할당 스킬 조회
    async searchAsgnSkill() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/unasgn-inqry-ty/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.unasgn-inqry-ty';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["GRID_ID"] = "divGridAuthGroupAlloc";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY_2"] = this.ASP_NEWCUST_KEY_2;
      requestData.sendData["USER_ID"] = this.USER_ID_2;
      requestData.sendData["USER_NM"] = this.USER_NM_2;
      requestData.sendData["USER_NICK"] = this.USER_NICK_2;
      requestData.sendData["NULLABLE"] = "IS NOT NULL";
      requestData.sendData["USER_ATTR_A"] = "";			//상담원명
      requestData.sendData["USER_ATTR_B"] = "";			//상담원명
      requestData.sendData["USER_ATTR_C"] = "";			//상담원명
      requestData.sendData["USER_ATTR_D"] = "";			//상담원명
      requestData.sendData["ROW_CNT"] = 100;				//페이징_ROW_CNT
      requestData.sendData["PAGES_CNT"] = 1;				//페이징_PAGES_CNT

      let response = await this.common_postCall(requestData);
      this.searchAsgnSkillCallback(response);
    },
    searchAsgnSkillCallback(response) {
      this.gridDataText4 = response.DATA;
      this.TOT_COUNT4 = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText4;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY_2 + this.gridDataText4[i]["TALK_INQRY_CD"] + this.gridDataText4[i]["USER_ID"];
        this.gridDataText4[i]["id"] = id;
        this.gridDataText4[i]["index"] = idx++;
      }
      //원본저장
      this.asgnGridData2 = [];
      for (let i in this.gridDataText4) {
        this.asgnGridData2.push(this.gridDataText4[i]);
      }

      //초기화
      this.seletedAuthGroupAlloc = [];
    },

    //스킬순서 변경버튼
    modifyBtn(FLAG) {
      if (this.seletedAuthGroupAlloc.length < 1) {
        this.common_alert(this.msgList.skillNotSeleted, "error")
        return
      }
      this.common_confirm(this.msgList.chkUpDown[FLAG], this.modifySkillLevel, FLAG, null, null, "chk")
    },

    //스킬순서 변경
    async modifySkillLevel(FLAG) {
      let submitList = [];
      let strBoolean = false;
      let requestData = {
        headers : {},
        sendData: {}
      };

      for (let i in this.seletedAuthGroupAlloc) {
        if (this.seletedAuthGroupAlloc[i].SKILL_RANK == "1") {
          strBoolean = true;
        }
      }

      if (strBoolean) {
        if (FLAG == "up") {
          this.common_alert(this.msgList.cantUp, "error");
          return;
        }
      }

      //submitList셋팅
      for (let i in this.seletedAuthGroupAlloc) {
        let obj = {
          divGridAuthGroupAlloc_CHECKBOX: 1,
          INQRY_TYP_NM                  : this.seletedAuthGroupAlloc[i].INQRY_TYP_NM,
          INQRY_TYPE                    : this.seletedAuthGroupAlloc[i].INQRY_TYPE,
          INQRY_USE_CHANNEL             : 2,
          TALK_INQRY_CD                 : this.seletedAuthGroupAlloc[i].TALK_INQRY_CD,
          SKILL_RANK                    : this.seletedAuthGroupAlloc[i].SKILL_RANK,
          USER_ID                       : this.seletedAuthGroupAlloc[i].USER_ID,
          DATA_FLAG                     : 'N',
        };
        submitList.push(obj);
      }

      // sendData 세팅
      requestData.sendData["FLAG"] = FLAG;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY_2;
      requestData.sendData["ASP_CUST_KEY"] = this.ASP_NEWCUST_KEY_2;
      requestData.sendData["seletedList"] = submitList;


      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/skill-priort/modify";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.skill-priort';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      let response = await this.common_postCall(requestData, false);
      this.modifySkillLevelCallback(response);
    },
    
    modifySkillLevelCallback(response) {
      console.log(response)
      this.searchSkillBtn();
      this.seletedAuthGroupAlloc = [];
    },

    //오른쪽 이동
    moveRight(tab) {
      let grid1_selected = this.selectedDivGridNoAllocList;  // 미할당 사용 체크박스
      let grid3_selected = this.seletedAuthGroupNoAlloc;  // 미할당 사용 체크박스
      let grid1 = this.gridDataText;     // 미할당사용자 그리드
      let grid2 = this.gridDataText2;       // 할당사용자 그리드
      let grid3 = this.gridDataText3;     // 미할당사용자 그리드
      let grid4 = this.gridDataText4;       // 할당사용자 그리드

      if (tab == 'tab1') {
        if (grid1_selected.length < 1) {
          this.common_alert(this.msgList.noAlloSelected, "error");
          return
        }
        for (let i in grid1_selected) {
          grid1.splice(grid1.indexOf(grid1_selected[i]), 1);
          grid2.push(grid1_selected[i]);
        }
        this.selectedDivGridNoAllocList = [];

      } else if (tab == 'tab2') {
        if (grid3_selected.length < 1) {
          this.common_alert(this.msgList.noAlloSelected, "error");
          return
        }
        for (let i in grid3_selected) {
          grid3.splice(grid3.indexOf(grid3_selected[i]), 1);
          grid4.push(grid3_selected[i]);
        }
        this.seletedAuthGroupNoAlloc = [];
      }
    },

    //왼쪽이동
    moveLeftBtn(tab) {
      if (tab == 'tab1') {
        if (this.selectedDivGridAllocList.length < 1) {
          this.common_alert(this.msgList.alloSelected, "error");
          return
        }
      } else if (tab == 'tab2') {
        if (this.seletedAuthGroupAlloc.length < 1) {
          this.common_alert(this.msgList.alloSelected, "error");
          return
        }
      }
      this.common_confirm(this.msgList.moveLeft[tab], this.moveLeft, tab, null, null, null);
    },
    moveLeft(tab) {
      let grid2_selected = this.selectedDivGridAllocList;   // 할당 사용 체크박스
      let grid4_selected = this.seletedAuthGroupAlloc;   // 할당 사용 체크박스
      let grid1 = this.gridDataText;     // 미할당사용자 그리드
      let grid2 = this.gridDataText2;       // 할당사용자 그리드
      let grid3 = this.gridDataText3;     // 미할당스킬 그리드
      let grid4 = this.gridDataText4;       // 할당스킬 그리드

      if (tab == 'tab1') {
        if (grid2_selected.length < 1) {
          this.common_alert(this.msgList.alloSelected, "error");
          return
        }

        for (let i in grid2_selected) {
          grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
          grid1.push(grid2_selected[i]);
        }
        this.selectedDivGridAllocList = [];
      } else if (tab == 'tab2') {
        if (grid4_selected.length < 1) {
          this.common_alert(this.msgList.alloSelected, "error");
          return
        }

        for (let i in grid4_selected) {
          grid4.splice(grid4.indexOf(grid4_selected[i]), 1);
          grid3.push(grid4_selected[i]);
        }
        this.seletedAuthGroupAlloc = [];
      }
    },

  },
  mounted() {
    //this.getCustList();
    this.custItems = this.$store.getters['userStore/GE_USER_COMPANY'];
    this.SPST_INQRY_TYP_CD2 = '*';

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  computed: {

  },
};
</script>

<style></style>
